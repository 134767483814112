import SectionTitle from "./SectionTitle";
import CallOfDutyLogo from "../img/call-of-duty-logo.png";
import GodOfWarLogo from "../img/god-of-war-logo.png";
import ChessLogo from "../img/chess-logo.png";

export default function Plays() {
  const emojis = [
    { name: "Guitar", emoji: "🎸" },
    { name: "Football", emoji: "⚽️" },
  ];
  const plays = [
    { name: "Call of Duty", image: CallOfDutyLogo },
    { name: "Chess", image: ChessLogo },
    { name: "God of War", image: GodOfWarLogo },
  ];
  return (
    <div>
      <SectionTitle title="I play" />
      <div className="grid grid-cols-6 gap-8 mt-5 sm:grid-cols-10 lg:grid-cols-10">
        {emojis.map(({ name, emoji }) => (
          <div key={name} className="relative grid items-center w-16 h-16 group justify-items-center">
            <p className="text-4xl">{emoji}</p>
            <p className="absolute hidden text-gray-500 text-2xs -bottom-2 group-hover:block">
              {name}
            </p>
          </div>
        ))}
        {plays.map(({ name, image }) => (
          <div key={name} className="relative grid items-center w-16 h-16 group justify-items-center">
            <img src={image} className="w-10 h-10" />
            <p className="absolute hidden text-gray-500 text-2xs -bottom-2 group-hover:block">
              {name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

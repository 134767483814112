import { useEffect } from "react";
import { useNavigate } from "react-router";

export default function Other({showModal}) {
    const navigate = useNavigate();
    useEffect(() => {
        showModal(true);
        navigate("/not-found");
    }, []);
    return <div className="h-screen"></div>
}
export default function Footer() {
  return (
    <div className="flex items-center justify-around p-2 bg-primary dark:bg-purple-900">
      <p className="text-xs text-white">
        This website was inspired by{" "}
        <a href="https://kitze.io" className="font-medium underline">
          @kitze
        </a>
      </p>
      <p className="text-xs text-white">Made with &#10084; by Harris Khawar</p>
    </div>
  );
}

export default function TechnologyLogo({ name, logo, link }) {
  return (
    <a
      className="relative grid items-center w-16 h-16 transition-all duration-300 justify-items-center group"
      href={link || "#"}
      target="blank"
      alt={name}
    >
      <img src={logo} className="w-10 h-10" />
      <p className="absolute hidden text-xs text-center text-gray-500 -bottom-2 dark:text-gray-400 group-hover:block">
        {name}
      </p>
    </a>
  );
}

import Education from "./Education";
import Work from "./Work";
import Projects from "./Projects";
import WeaponsOfChoice from "./WeaponsOfChoice";
import OtherWeapons from "./OtherWeapons";
import Plays from "./Plays";

export default function Home() {
  return (
    <div className="grid min-h-screen gap-y-16">
      <Education />
      <Work />
      <WeaponsOfChoice />
      <OtherWeapons />
      <Projects />
      <Plays />
    </div>
  );
}

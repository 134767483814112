import { Fragment } from "react";
import { Transition } from "@headlessui/react";

import SectionTitle from "./SectionTitle";
import ExperienceCard from "./EducationCard";

import AmherstCollegeLogo from "../img/experience/amherst-college-logo.png";
import GoettingenUniversityLogo from "../img/experience/goettingen-university-logo.png";

export default function Education() {
  const education = [
    {
      image: AmherstCollegeLogo,
      title: "Computer Science & German",
      subtitle: "Amherst College, MA",
    },
    {
      image: GoettingenUniversityLogo,
      title: "Information Technology",
      subtitle: "Göttingen University, Germany",
    },
  ];
  return (
    <div className="">
      <SectionTitle title="I studied" />
      <Transition
        as={Fragment}
        appear={true}
        show={true}
        enter="transition duration-1000 ease-out"
        enterFrom="lg:opacity-0 lg:translate-y-10"
        enterTo="opacity-100 translate-y-0"
      >
        <div className="grid gap-4 mt-5 lg:justify-items-center lg:grid-cols-3">
          {education.map(({ image, title, subtitle }) => (
            <ExperienceCard
              key={title}
              image={image}
              title={title}
              subtitle={subtitle}
            />
          ))}
        </div>
      </Transition>
    </div>
  );
}

import { Fragment } from "react";
import { Transition } from "@headlessui/react";

import SectionTitle from "./SectionTitle";
import WorkCard from "./WorkCard";

import CMCLogo from "../img/experience/cmc-logo.png";
import JPMorganLogo from "../img/experience/jpmorgan-logo.jpg";
import AmherstCollegeLogo from "../img/experience/amherst-college-logo.png";

export default function Work() {
  const works = [
    {
      title: "Associate Software Engineer",
      subtitle: "Cambridge Marketing Concepts, MA",
      image: CMCLogo,
    },
    {
      title: "Software Engineer Intern",
      subtitle: "JPMorgan Chase & Co., MA",
      image: JPMorganLogo,
    },
    {
      title: "Research Assistant",
      subtitle: "German, Amherst College, MA",
      image: AmherstCollegeLogo,
    },
  ];
  return (
    <div className="">
      <SectionTitle title="I worked as" />
      <Transition
        as={Fragment}
        appear={true}
        show={true}
        enter="transition duration-[1500ms] ease-out"
        enterFrom="lg:opacity-0 lg:translate-y-10"
        enterTo="opacity-100 translate-y-0"
      >
        <div className="grid gap-4 mt-5 lg:justify-items-center lg:grid-cols-3">
          {works.map(({ title, subtitle, image }) => (
            <WorkCard
              key={title}
              title={title}
              subtitle={subtitle}
              image={image}
            />
          ))}
        </div>
      </Transition>
    </div>
  );
}

import { ArrowCircleUpIcon } from "@heroicons/react/solid";

export default function ScrollToTopButton({ showButton, buttonHandler }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <button
      onClick={buttonHandler}
      className={classNames(
        !showButton ? "-bottom-12 opacity-0" : "bottom-12 opacity-100", "fixed right-12  transition-all hidden lg:block" )}
      title={"Back to top"}
    >
      <ArrowCircleUpIcon className="w-8 h-8 cursor-pointer text-highlight hover:text-tertiary animate-bounce" />
    </button>
  );
}

import { Fragment } from "react";
import { Transition } from "@headlessui/react";

import TechnologyLogo from "./TechnologyLogo";

import ReactLogo from "../img/react-logo.png";
import FirebaseLogo from "../img/firebase-logo.png";
import TailwindLogo from "../img/tailwind-logo.png";
import NextLogo from "../img/next-logo.png";
import SectionTitle from "./SectionTitle";
import FlutterLogo from "../img/flutter-logo.png";
import DartLogo from "../img/dart-logo.png";

export default function WeaponsOfChoice() {
  const weapons = [
    { name: "NextJS", logo: NextLogo, link: "https://nextjs.org/" },
    { name: "React", logo: ReactLogo, link: "https://reactjs.org/" },
    { name: "Tailwind", logo: TailwindLogo, link: "https://tailwindcss.com/" },
    {
      name: "Firebase",
      logo: FirebaseLogo,
      link: "https://firebase.google.com/",
    },
    { name: "Flutter", logo: FlutterLogo, link: "https://flutter.dev/" },
    { name: "Dart", logo: DartLogo, link: "https://dart.dev/" },
  ];
  return (
    <div className="">
      <SectionTitle title="I am using" />
      <Transition
        as={Fragment}
        appear={true}
        show={true}
        enter="transition duration-[2000ms] ease-out"
        enterFrom="lg:opacity-0 lg:translate-y-10"
        enterTo="lg:opacity-100 lg:translate-y-0"
      >
        <div className="grid grid-cols-6 gap-8 mt-5 sm:grid-cols-10 lg:grid-cols-10">
          {weapons.map(({ name, logo, link }) => (
            <TechnologyLogo key={name} name={name} logo={logo} link={link} />
          ))}
        </div>
      </Transition>
    </div>
  );
}

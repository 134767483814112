import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Travel from "./components/Travel";
import ContactForm from "./components/ContactForm";

import WebDevProjects from "./components/WebDevProjects";
import GraphicDesign from "./components/GraphicDesign";
import Research from "./components/Research";
import Programming from "./components/Programming";
import Other from "./components/Other";

import NotFound from "./components/NotFound";

import Modal from "./components/Modal";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Footer from "./components/Footer";

function App() {
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleScroll = () => {
    const position = window.scrollY;
    if (position > 200) {
      setShowScrollToTopButton(true);
    } else {
      setShowScrollToTopButton(false);
    }
  };

  const scrollToTopBtnHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="transition-all duration-500 bg-gray-100 dark:bg-gray-800">
      <Modal open={showModal} setOpen={setShowModal}/>
      <div className="mx-auto max-w-[1080px] mb-10">
        <Navbar/>
        <div className="mx-5 my-16 lg:mx-10">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/travel" element={<Travel />} />
            <Route exact path="/contact" element={<ContactForm />}/>
            <Route exact path="/projects/web-development" element={<WebDevProjects />} />
            <Route exact path="/projects/graphic-design" element={<GraphicDesign />} />
            <Route exact path="/projects/research" element={<Research showModal={setShowModal}/>} />
            <Route exact path="/projects/programming" element={<Programming showModal={setShowModal}/>} />
            <Route exact path="/projects/other" element={<Other showModal={setShowModal}/>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
      <ScrollToTopButton
        showButton={showScrollToTopButton}
        buttonHandler={scrollToTopBtnHandler}
      />
      <Footer />
    </div>
  );
}

export default App;

import { useContext } from "react";
import { Switch } from "@headlessui/react";
import { ThemeContext } from "../context/ThemeContext";

export default function DarkModeSwitch() {
  const {theme, setTheme} = useContext(ThemeContext);
  return (
    <Switch
      checked={theme}
      onChange={setTheme}
      className={`${theme ? "bg-highlight" : "bg-white"}
          relative inline-flex flex-shrink-0 h-[32px] w-[58px] shadow-md border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">Use setting</span>
      <div
        aria-hidden="true"
        className={`${theme ? "translate-x-6" : "translate-x-0"}
            transform ring-0 transition ease-in-out duration-200 text-3xl -translate-y-1`}
      >
        {theme ? "🌚" : "🌝"}
      </div>
    </Switch>
  );
}

import LoadingSpinner from "./LoadingSpinner";

export default function Button({ children, handler, type, disabled }) {
  return (
    <button
      type={type || "button"}
      disabled={disabled || false}
      className="flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-secondary dark:hover:bg-purple-900 dark:bg-purple-800 sm:text-sm"
      onClick={handler || (() => {})}
    >
      {!disabled && children}
      {disabled && <LoadingSpinner/>}
    </button>
  );
}

import { useNavigate } from "react-router";

import ProjectCard from "./ProjectCard";
import SectionTitle from "./SectionTitle";
import projects from "../data/projects";

import { ChevronDoubleRightIcon } from "@heroicons/react/outline";

export default function Projects() {
  const navigate = useNavigate();
  
  return (
    <div className="mb-10">
      
      <SectionTitle title="I built" />
      <div className="grid mt-5 gap-x-8 gap-y-8 lg:grid-cols-4 justify-items-center">
        {projects.slice(0, 7).map(({ title, description, image, link, logos }) => (
          <ProjectCard
            key={title}
            title={title}
            description={description}
            image={image}
            link={link}
            logos={logos}
          />
        ))}

        <div className="my-auto" onClick={() => navigate("/projects/web-development")}>
          <ChevronDoubleRightIcon className="w-16 h-16 text-gray-500 transition-all cursor-pointer hover:scale-125" />
        </div>
      </div>
    </div>
  );
}

import NotFoundImage from "../img/404.png";

export default function NotFound() {
  return (
    <div className="grid content-start h-screen gap-12 justify-items-center">
      <img src={NotFoundImage} className="h-64 p-6 rounded-lg w-96" alt="Not Found"/>
      <div className="grid gap-2">
          <p className="text-3xl font-semibold text-stone-700 dark:text-white">PAGE NOT FOUND 🤷‍♂️</p>
      </div>
    </div>
  );
}

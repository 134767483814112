import { Fragment, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import { useForm, ValidationError } from "@formspree/react";

import SectionTitle from "./SectionTitle";
import Button from "./Button";

import Pikachu from "../img/pikachu.png";

export default function ContactForm() {
  const [state, handleSubmit] = useForm("xdobzbrj");
  const [wrongCaptcha, setWrongCaptcha] = useState(false);
  const captchaRef = useRef();

  if (state.succeeded) {
    return (
      <div className="h-screen">
        <SectionTitle title="Message Sent ✅" />
      </div>
    );
  }

  const submitHandler = (e) => {
    console.log("submitHandler");
    e.preventDefault();
    const response = captchaRef.current.value.trim().toLowerCase();
    if (response === "pickachu") {
      handleSubmit(e);
    } else {
      setWrongCaptcha(true);
    }
  };

  // This function evaluates tailwind classes based on boolean values
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="h-screen">
      <SectionTitle title="Contact Me" />
      <Transition
        as={Fragment}
        appear={true}
        show={true}
        enter="transition duration-1000 ease-out"
        enterFrom="lg:opacity-0 lg:translate-y-10"
        enterTo="opacity-100 translate-y-0"
      >
        <form className="grid gap-8 mt-12 mb-6" onSubmit={submitHandler}>
          <div className="grid items-center lg:space-x-4 lg:flex">
            <label
              htmlFor="name"
              className="w-20 font-semibold lg:text-right dark:text-gray-400"
            >
              Name:
            </label>
            <input
              className="py-3 pl-4 text-gray-400 transition-all duration-300 rounded-md shadow-md dark:bg-gray-600 lg:w-72 focus:outline-none focus:ring-2 focus:ring-highlight focus:ring-opacity-75"
              type="text"
              placeholder="you may leave this blank..."
              id="name"
              name="name"
            />
            <ValidationError prefix="Name" field="name" errors={state.errors} />
          </div>

          <div className="grid items-center lg:space-x-4 lg:flex">
            <label
              htmlFor="email"
              className="w-20 font-semibold lg:text-right dark:text-gray-400"
            >
              Email:
            </label>
            <input
              className="py-3 pl-4 text-gray-400 transition-all duration-300 rounded-md shadow-md dark:bg-gray-600 lg:w-72 focus:outline-none focus:ring-2 focus:ring-highlight focus:ring-opacity-75"
              type="text"
              placeholder="this too..."
              id="email"
              name="email"
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
          </div>

          <div className="grid lg:space-x-4 lg:flex">
            <label
              htmlFor="message"
              className="w-20 font-semibold lg:text-right dark:text-gray-400"
            >
              Message:
            </label>
            <textarea
              className="py-3 pl-4 text-gray-400 transition-all duration-300 rounded-md shadow-md dark:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-highlight focus:ring-opacity-75"
              type="text"
              placeholder="not this though..."
              id="message"
              name="message"
              rows="4"
              cols="40"
              required
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
          </div>

          <div className="flex items-center w-full space-x-8">
            <img src={Pikachu} className="w-16 h-16" />
            <div>
              <label className="text-sm dark:text-gray-400">Who is this?</label>
              <div className="flex justify-start space-x-5">
                <input
                  id="captcha"
                  name="captcha"
                  className={classNames(
                    wrongCaptcha ? "outline outline-red-800" : "outline-none",
                    "py-1 pl-4 text-gray-400 transition-all duration-300 rounded-md shadow-md dark:bg-gray-600 focus:outline-none"
                  )}
                  ref={captchaRef}
                  type="text"
                  
                />
                <div className="w-full lg:w-40">
                  <Button disabled={state.submitting} type="submit">
                    Send Message
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Transition>
    </div>
  );
}

import { ExclamationCircleIcon } from "@heroicons/react/outline";

export default function ProjectCard({
  image,
  title,
  description,
  link,
  logos,
  notDeployed,
}) {

  function classNames() {
    return [...arguments].filter(Boolean).join(" ");
  }
  return (
    <a
      href={link}
      className={classNames(notDeployed ? "cursor-default": "cursor-pointer hover:scale-105", "grid items-center content-between transition-all bg-white border rounded-lg shadow-md  dark:bg-gray-900 dark:border-gray-900")}
      title={notDeployed ? "Not Deployed": title}
      target="blank"
    >
      <img
        src={image}
        className="dark:border-b dark:border-gray-900 rounded-t-md"
      />

      <div className="grid content-between m-5">
        <p className="text-lg font-semibold dark:text-white">{title}</p>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
      <div className="mx-5 mb-3">
        <p className="text-xs dark:text-white">Built With:</p>
        <div className="flex justify-start mt-3">
          {logos.map((logo) => (
            <img key={logo} src={logo} className="w-4 h-4 mr-3" />
          ))}
          {notDeployed && <ExclamationCircleIcon className="w-5 h-5 ml-auto text-red-600" alt="Not Deployed"/>}
        </div>
        
      </div>
    </a>
  );
}

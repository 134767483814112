import { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";

import SectionTitle from "./SectionTitle";

import WebDevImage from "../img/design/web-dev.png";
import TravelImage from "../img/design/travel.png";
import PhotoshopImage from "../img/design/photoshop.png";
import ResumeImage from "../img/design/resume.png";
import ProgrammingImage from "../img/design/programming.png";
import ResearchImage from "../img/design/research.png";
import BonfireImage from "../img/design/bonfire.jpg";
import JobstopImage from "../img/design/jobstop.jpg";
import IvyprepImage from "../img/design/ivyprep.jpg";
import HoliImage from "../img/design/holi.jpg";
import SummerFestImage from "../img/design/summer-fest.jpg";
import ToastmasterImage from "../img/design/toastmaster.jpg";
import ToastmastersImage from "../img/design/toastmasters.png";
import SouthparkImage from "../img/design/southpark.jpg";
import SkootsImage from "../img/design/skoots.png";
import LoadingSpinner from "./LoadingSpinner";

export default function GraphicDesign() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  return (
    <div className="min-h-screen">
      <SectionTitle title="Graphic Design Projects" />
      {loading && (
        <div className="flex justify-center my-10">
          <LoadingSpinner size="10" color="primary" />
        </div>
      )}
      {!loading &&
      <Transition
        as={Fragment}
        appear={true}
        show={true}
        enter="transition duration-[1000ms] ease-out"
        enterFrom="lg:opacity-0 lg:translate-y-10"
        enterTo="lg:opacity-100 lg:translate-y-0"
      >
        <div className="grid items-center gap-8 mt-10 lg:grid-cols-3 justify-items-center">
          <img src={PhotoshopImage} className="rounded-md shadow-md" />
          <img src={TravelImage} className="rounded-md shadow-md" />
          <img src={WebDevImage} className="rounded-md shadow-md" />

          <img
            src={SouthparkImage}
            className="rounded-md shadow-md lg:col-span-3"
          />

          <img src={IvyprepImage} className="rounded-md shadow-md" />
          <img
            src={JobstopImage}
            className="rounded-md shadow-md lg:col-span-2"
          />

          <img src={ProgrammingImage} className="rounded-md shadow-md" />
          <img src={ResumeImage} className="rounded-md shadow-md" />
          <img src={ResearchImage} className="rounded-md shadow-md" />

          <img
            src={BonfireImage}
            className="rounded-md shadow-md lg:col-span-3"
          />

          <img src={HoliImage} className="rounded-md shadow-md" />
          <img
            src={ToastmastersImage}
            className="rounded-md shadow-md lg:col-span-2 lg:row-span-2"
          />
          <img src={SummerFestImage} className="rounded-md shadow-md" />

          <img src={ToastmasterImage} className="rounded-md shadow-md" />
          <img
            src={SkootsImage}
            className="rounded-md shadow-md lg:col-span-2"
          />
        </div>
      </Transition>}
    </div>
  );
}

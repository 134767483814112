import { Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function SectionTitle({ title }) {
  return (
    <Transition
      as={Fragment}
      appear={true}
      show={true}
      enter="transition duration-[1000ms] ease-out"
      enterFrom="lg:opacity-0"
      enterTo="opacity-100"
    >
      <h1 className="text-xl font-bold transition-all duration-200 text-slate-800 dark:text-white">
        {title}
      </h1>
    </Transition>
  );
}

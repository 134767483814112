export default function WorkCard({ image, title, subtitle }) {
  return (
    <div className="flex items-center p-3 transition-all bg-white rounded-lg shadow-md w-fit dark:bg-gray-700 hover:scale-105">
      <img src={image} className="w-12 h-12 rounded-lg" />
      <div className="grid content-center mx-4 rounded-r-lg">
        <p className="font-medium text-md dark:text-white">{title}</p>
        <p className="text-xs text-gray-500">{subtitle}</p>
      </div>
    </div>
  );
}

import { Fragment, useEffect } from "react";
import { Transition } from "@headlessui/react";

import SectionTitle from "./SectionTitle";
import ProjectCard from "./ProjectCard";

import projects from "../data/projects";

export default function WebDevProjects() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <SectionTitle title="Web Development Projects" />
      <Transition
        as={Fragment}
        appear={true}
        show={true}
        enter="transition duration-[1000ms] ease-out"
        enterFrom="lg:opacity-0 lg:translate-y-10"
        enterTo="lg:opacity-100 lg:translate-y-0"
      >
        <div className="grid gap-12 mt-10 lg:grid-cols-3 justify-items-center">
          {projects.map(({ title, description, image, link, logos, notDeployed }) => (
            <ProjectCard
              key={title}
              title={title}
              description={description}
              image={image}
              link={link}
              logos={logos}
              notDeployed={notDeployed}
            />
          ))}
        </div>
      </Transition>
    </div>
  );
}

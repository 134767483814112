import React, { Suspense } from "react";
import LoadingSpinner from "./LoadingSpinner";

import AmsRembrandtplein from "../img/travel/ams-rembrandtplein.jpg";
import AmsStation from "../img/travel/ams-station.jpg";
import AmsZaandam from "../img/travel/ams-zaandam.jpg";
import BerlinFood from "../img/travel/berlin-food.jpg";
import BerlinMe from "../img/travel/berlin-me.jpg";
import BrusselsAlbert from "../img/travel/brussels-albert.jpg";
import BrusselsMe from "../img/travel/brussels-me.jpg";
import EisenachBach from "../img/travel/eisenach-bach.jpg";
import EisenachOrgan from "../img/travel/eisenach-organ.jpg";
import EisenachWaltburg from "../img/travel/eisenach-waltburg.jpg";
import GottiPicnic from "../img/travel/gotti-picnic.jpg";
import GottiStreet from "../img/travel/gotti-street.jpg";
import GottiTour from "../img/travel/gotti-tour.jpg";
import ParisArc from "../img/travel/paris-arc.jpg";
import ParisEiffel from "../img/travel/paris-eiffel.jpg";
import ParisLouvre from "../img/travel/paris-louvre.jpg";
import TurkeyFountain from "../img/travel/turkey-fountain.jpg";
import TurkeyHagia from "../img/travel/turkey-hagia.jpg";
import TurkeyShisha from "../img/travel/turkey-shisha.jpg";
import WroclawCathedral from "../img/travel/wroclaw-cathedral.jpg";
import WroclawStation from "../img/travel/wroclaw-station.jpg";
import WroclawZoo from "../img/travel/wroclaw-zoo.jpg";

const SwiperComponent = React.lazy(() => import("./TravelSwiperComponent"));

export default function Travel() {
  const locations = [
    {
      title: "Wrocław, Poland 🇵🇱",
      slides: [
        {
          image: WroclawZoo,
          title: "Wrocław Zoo",
          body: "Call me childish, but I am the kind of person who would go to a zoo twice before thinking about going to a museum. My Polish friend was surprised when I told her the first place I wanted to visit in Wroclaw. But, hands down, this is the best zoo I have ever been too. Spread over 30 hectares, the Wroclaw Zoological Garden has over ten thousand animals. It has several dedicated areas like the Africarium, the Madagascar Pavilion, the Odrarium etc. My favorite part was the oceanarium's penguins.",
        },
        {
          image: WroclawCathedral,
          title: "Cathedral of St. John",
          body: "Also known as the Wroclaw Cathedral, Saint John the Baptist's Cathedral is amoungst the many high rising cathedrals in Wroclaw. Destroyed and rebuilt many times, it represents the religious history of Wroclaw. Inside the cathedral lie precious statues and paintings: a triptych Dormition of Mary from 1552, a pulpit, oaken stall from 1662-1665 and many tombstones from Middle Ages, Mannerism and Baroque. Outside the cathedral, sparkling decorations for Christmas with a background of a deep blue night sky made it one of the most breathtaking sights I've ever seen.",
        },
        {
          image: WroclawStation,
          title: "Wrocław Train Station",
          body: "Amoung all the places I have visited the most lavish indoors are those of the buildings in Dubai followed by this train station in Wroclaw. It has multiple underground floors with escalators and elevators pacing up and down around every corner. Once you get out of the train it is hard to tell whether you got dropped off at a train station or a shopping mall.",
        },
      ],
    },
    {
      title: "Amsterdam, Netherlands 🇳🇱",
      slides: [
        {
          image: AmsRembrandtplein,
          title: "Rembrandtplein Square",
          body: "The Rembrandtplein square in Amsterdam is a unique sight. On one hand, it is a sign of respect to the painter with the same name and to one of his most known artworks — The Night Watch. On the other hand, it is the most vibrant part of the city with dreamy street lights, stylish cafes and an overall amazing nightlife.",
        },
        {
          image: AmsStation,
          title: "Amsterdam Station",
          body: "Amsterdam’s architecture impressed me from the first second I stepped into the city. The overall structure is very European but a lot of elements have a unique touch to them. After exiting the central train station, I looked back at the imposing building with its high towers, huge clocks, and eye-catching golden details and felt so inspired by the commitment and effort of the architects and builders. Almost every building in Amsterdam is unique in its appearance. They truly represent Amsterdam's culture and the love of the citizens for their city.",
        },
        {
          image: AmsZaandam,
          title: "Zaandam",
          body: "During my visit, I stayed in an apartment in Zandaam. Despite having to take a subway for multiple stops to the Amsterdam city center, I didn’t regret choosing this location. With bright colored facades and elegant borders, the buildings of Zaandam are the only ones of their kind. It was a very quaint and peaceful area to stay in — perfectly complementing the frenzy of the city center."
        }
      ],
    },
    {
      title: "Brussels, Belgium 🇧🇪",
      slides: [
        {
          image: BrusselsMe,
          title: "Place de l’Albertine",
          body: "Right across Mont des Arts is Place de l'Albertine. Known as “the hill of the arts”, it is the second biggest city square of Brussels. Surrounded by opulent guildhalls and parliament houses, this square is the starting point for many of Brussels' marketplaces.",
        },
        {
          image: BrusselsAlbert,
          title: "Mont des Arts",
          body: "My trip to Brussels was way shorter than I had planned. I headed there from Amsterdam and was supposed to go to Berlin right after. But unfortunately, my bus got late and I arrived much later than expected. Nonetheless, there was enough time to stroll around the city and see some of the popular spots. This picture is of King Albert I's statue. Normally referred to as the man on a horse statue, it is a very popular meet up spot for people spending the night out.",
        },
      ],
    },
    {
      title: "Paris, France 🇫🇷",
      slides: [
        {
          image: ParisLouvre,
          title: "The Louvre Museum",
          body: "The Louvre's appearance and atmosphere manifest its title of the largest art museum in the world. The spectacular Renaissance style buildings surround the pyramid made of glass and steel making the entrance truly only one of its kind. I went there twice and admired the architecture every time, although I couldn't get the chance to go inside. The first time, all tickets were sold out for the days I was in Paris. The second time I got there 30 minutes after the museum closed indefinitely due to the COVID-19 pandemic. Third time is the charm!",
        },
        {
          image: ParisEiffel,
          title: "The Eiffel Tower",
          body: "Paris looks tiny from atop the Eiffel Tower’s second floor. I went up on a rainy winter day. Although I enjoyed the view, the rain had washed away the saturation of Paris' colors. It’s worth mentioning that the Eiffel Tower experience starts before you even reach the ticket booths — it is the street vendors, gamblers, performance artists and inumerable tourists that make the experience stand out.",
        },
        {
          image: ParisArc,
          title: "Arc de Triomphe",
          body: "I saw the Arc de Triomphe on a sunny day. Getting underneath it was a challenge and given my French language skills topped with the French don't-bother-me attitude, I couldn’t find the underground passage to it. So I just admired it from afar as the sun dipped down the horizon behind it.",
        },
      ],
    },
    {
      title: "Berlin, Germany 🇩🇪",
      slides: [
        {
          image: BerlinFood,
          title: "Chelany Resturaunt",
          body: "It’s not easy to travel around Berlin because it is huge and has a lot of traffic. I usually ended up walking for over half an hour getting from one place to another, but food like this made it a 100% worth it. There are South Asian restaurants everywhere in Germany, but very few of them still cater the authentic tastes. This hidden Pakistani restaurant showed up surprisingly in the middle of Berlin and brought me a piece of home.",
        },
        {
          image: BerlinMe,
          title: "Berliner Dom",
          body: "The Berlin Cathedral was my last stop before heading out of the city. I spent over 5 hours walking around this area and not for a single moment I felt tired or bored. As I stood on Friedrichs Bridge, to my right was the skyscraping television tower of Berlin, on my right was the Altes Museum and the Lustgarten, in front of me was the river Spree and behind me was, of course, the spectacular Berliner Dom. Easily one of the most entertaining evenings of my life.",
        },
      ],
    },

    {
      title: "Eisenach, Germany 🇩🇪",
      slides: [
        {
          image: EisenachOrgan,
          title: "Bach's Organ",
          body: "The tour guide needed a volunteer to pump air into the organ in order to play it and I couldn’t miss the chance to get my hands on Bach’s organ. Luckily, I was standing very close to him and started towering over him as I raised both my hands. Although pumping the organ was a very monotonous task, I can never forget that experience.",
        },
        {
          image: EisenachBach,
          title: "Bach Haus",
          body: "I visited Eisenach as part of a month-long workshop for German language with around 20 people. I had no idea it was the birthplace of Johann Sebastian Bach. His actual house is now a part of the museum dedicated entirely to him. It houses a huge variety of instruments that were originally meant for Bach’s personal use. The experience was not that of any museum. We had an amazing guided tour during which we even got to see the instruments in action.",
        },
        {
          image: EisenachWaltburg,
          title: "Waltburg Castle",
          body: "Over a thousand years old, this castle has so much history. It was home to St. Elisabeth of Hungary, a refuge for Martin Luther, a retreat for Goethe and the birthplace of the German flag. Located atop a hill, this castle offers breathtaking views at the surrounding lush green valleys. Moreover, the structure and indoor decorations were so well preserved that being there felt like going back into time.",
        },
      ],
    },
    {
      title: "Istanbul, Turkey 🇹🇷",
      slides: [
        {
          image: TurkeyFountain,
          title: "German Fountain",
          body: "The first monument I took a picture next to in Europe was this fountain constructed to commemorate German emperor Wilhelm II’s visit. Underwhelmingly enough, it is simply called the German Fountain.",
        },
        {
          image: TurkeyShisha,
          title: "Shisha Bar",
          body: "I was in the city center till around midnight and I’ve got to say, Turkish nightlife is unlike any other. It is radically different from Europe and not similar to South Asia either, although kind of the same as Arabic countries when it comes to Shisha/Hookah. Other than that, delicious food, outstanding hospitality and a very uplifting vibe. My only regret is that I didn’t have enough time.",
        },
        {
          image: TurkeyHagia,
          title: "Hagia Sophia",
          body: "I was in Istanbul for only 14 hours. I was flying to Germany with Turkish airlines and I had purposely chosen a flight with a layover long enough for me to get out of the airport. I reached there around 11 am and took a cab to the legendary Constantinople immediately. Visiting Hagia Sophia Museum, the Blue Mosque and that entire area of iconic Ottoman architecture was a dream come true for me. Having read so much about the Ottoman Caliphate and played Assassin’s Creed multiple times, being there in person was surreal.",
        },
      ],
    },
  ];

  return locations.map(({ title, slides }) => (
    <Suspense
      key={title}
      fallback={
        <div className="flex items-center justify-center my-32 h-96">
          <LoadingSpinner size="16" color="white" />
        </div>
      }
    >
      <SwiperComponent title={title} slides={slides} />
    </Suspense>
  ));
}

import { Fragment } from "react";
import { Transition } from "@headlessui/react";

import SectionTitle from "./SectionTitle";
import TechnologyLogo from "./TechnologyLogo";
import MongoDBLogo from "../img/mongodb-logo.png";
import AngularLogo from "../img/angular-logo.png";
import VueLogo from "../img/vue-logo.png";
import PythonLogo from "../img/python-logo.png";
import HerokuLogo from "../img/heroku-logo.png";
import JavaLogo from "../img/java-logo.png";
import BootstrapLogo from "../img/bootstrap-logo.png";
import GithubLogo from "../img/github-logo.png";
import Route53Logo from "../img/route53-logo.png";
import NodeLogo from "../img/node-logo.png";

export default function OtherWeapons() {
  const otherWeapons = [
    { name: "Python", logo: PythonLogo, link: "https://www.python.org/" },
    { name: "Java", logo: JavaLogo, link: "https://www.java.com/en/" },
    { name: "NodeJS", logo: NodeLogo, link: "https://nodejs.org/" },
    { name: "VueJS", logo: VueLogo, link: "https://vuejs.org/" },
    { name: "Angular", logo: AngularLogo, link: "https://angular.io/" },
    {
      name: "Bootstrap",
      logo: BootstrapLogo,
      link: "https://getbootstrap.com/",
    },
    { name: "MongoDB", logo: MongoDBLogo, link: "https://www.mongodb.com/" },
    { name: "Heroku", logo: HerokuLogo, link: "https://www.heroku.com/" },
    { name: "GitHub", logo: GithubLogo, link: "https://www.github.com/" },
    {
      name: "Route53",
      logo: Route53Logo,
      link: "https://aws.amazon.com/route53/",
    },
  ];

  return (
    <div className="">
      <SectionTitle title="I have used" />
      <Transition
        as={Fragment}
        appear={true}
        show={true}
        enter="transition duration-[2000ms] ease-out"
        enterFrom="lg:opacity-0 lg:translate-y-10"
        enterTo="lg:opacity-100 lg:translate-y-0"
      >
        <div className="grid grid-cols-6 gap-8 mt-5 sm:grid-cols-10 lg:grid-cols-10">
          {otherWeapons.map(({ name, logo, link }) => (
            <TechnologyLogo key={name} name={name} logo={logo} link={link} />
          ))}
        </div>
      </Transition>
    </div>
  );
}

import { Fragment } from "react";
import { NavLink } from "react-router-dom";

import { Popover, Transition } from "@headlessui/react";

import {
  MenuIcon,
  ViewGridIcon,
  XIcon,
  GlobeAltIcon,
  AcademicCapIcon,
  DocumentDownloadIcon,
  GlobeIcon,
} from "@heroicons/react/outline";

import {
  ChevronDownIcon,
  MailIcon,
  PencilIcon,
  CodeIcon,
} from "@heroicons/react/solid";

import HarrisIcon from "../img/harris-icon.png";
import DarkModeSwitch from "./DarkModeSwitch";
import LinkedInLogo from "../img/linkedin-logo.png";
import GithubLogo from "../img/github-logo.png";
import InstagramLogo from "../img/instagram-logo.png";
import Resume from "../downloads/Resume-2021-Harris-Khawar.pdf";

// Components to map to navbar items
const projects = [
  {
    name: "Web Development",
    description:
      "I spend most of my time building and perfecting web applications. It is my work, hobby, and passion.",
    link: "web-development",
    icon: GlobeAltIcon,
  },
  {
    name: "Graphic Design",
    description:
      "I have been editting photos and designing graphics ever since I got a computer. Before that, I was a huge fan of sketching.",
    link: "graphic-design",
    icon: PencilIcon,
  },
  {
    name: "Research",
    description:
      "My research interests have changed quite frequently over the years. I am quick at gaining and loosing interest in new things.",
    link: "research",
    icon: AcademicCapIcon,
  },
  {
    name: "Programming",
    description:
      "These are mostly curricular projects that I worked on in college and high school.",
    link: "programming",
    icon: CodeIcon,
  },
  {
    name: "Other",
    description: "",
    link: "other",
    icon: ViewGridIcon,
  },
];

const contact = [
  { name: "Contact", method: "harrismkhawar@gmail.com", icon: MailIcon },
];

const socialMedia = [
  {
    name: "LinkedIn",
    description: "Connect with me on LinkedIn and find out more about my work.",
    link: "https://www.linkedin.com/in/harris-mahmood-khawar-7803a2135/",
    image: LinkedInLogo,
  },
  {
    name: "Github",
    description: "Connect with me on Github to learn more about my projects.",
    link: "https://github.com/HarrisKhawar",
    image: GithubLogo,
  },
  {
    name: "Instagram",
    description: "Follow me on Instagram for a follow back.",
    link: "https://www.instagram.com/harriskhawer/",
    image: InstagramLogo,
  },
];

// This function evaluates tailwind classes based on boolean values
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {

  return (
    <Popover className="relative">
      <div className="px-4 mx-auto max-w-7xl sm:px-6">
        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
          {/* Harris Avatar */}
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <NavLink to="/">
              <span className="sr-only">Harris Khawar</span>
              <div className="flex items-center">
                <img
                  src={HarrisIcon}
                  className="w-16 transition-all rounded-full duration-400 active:scale-90 hover:scale-105"
                  alt="Harris Khawar Avatar"
                />
              </div>
            </NavLink>
          </div>

          {/* Projects Popover */}
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md focus:outline-none">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="w-6 h-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open
                        ? "text-gray-900 dark:text-gray-100"
                        : "text-gray-500",
                      "group rounded-md inline-flex items-center dark:hover:text-gray-300 text-base font-medium hover:text-gray-900 focus:outline-none"
                    )}
                  >
                    <span>Projects</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 w-screen max-w-md px-2 mt-3 -ml-4 transform sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 px-5 py-6 bg-white dark:bg-gray-900 sm:gap-8 sm:p-6">
                          {projects.map((item) => (
                            <Popover.Button
                              key={item.name}
                              as={NavLink}
                              to={`/projects/${item.link}`}
                              className="flex items-center p-3 -m-3 rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800"
                            >
                              {item.icon ? (
                                <item.icon
                                  className="flex-shrink-0 w-8 h-8 text-primary"
                                  aria-hidden="true"
                                />
                              ) : (
                                <img
                                  src={item.image}
                                  className="w-6 h-6"
                                  alt={item.name}
                                />
                              )}
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900 dark:text-white">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-xs text-gray-600">
                                  {item.description}
                                </p>
                              </div>
                            </Popover.Button>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            {/* Resume Download Link */}
            <a
              href={Resume}
              className="flex items-center text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-300"
              download
            >
              Resume
              <DocumentDownloadIcon
                className="w-5 h-5 ml-2 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </a>

            {/* Travel Page Router Link */}
            <div className="flex items-center text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-300">
              <NavLink to="/travel">Travel</NavLink>
              <GlobeIcon
                className="w-5 h-5 ml-2 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </div>

            {/* Connect Popover */}
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open
                        ? "text-gray-900 dark:text-gray-100"
                        : "text-gray-500",
                      "group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 dark:hover:text-gray-300 focus:outline-none"
                    )}
                  >
                    <span>Connect</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 w-screen max-w-md px-2 mt-3 transform left-1/2 -translate-x-1/3 sm:px-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 w-80">
                        <div className="relative grid gap-6 px-5 py-6 bg-white dark:bg-gray-900 sm:gap-8 sm:p-6">
                          {socialMedia.map((item) => (
                            <a
                              key={item.name}
                              href={item.link}
                              className="flex items-center p-3 -m-3 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.icon ? (
                                <item.icon
                                  className="flex-shrink-0 w-8 h-8 text-primary"
                                  aria-hidden="true"
                                />
                              ) : (
                                <img
                                  src={item.image}
                                  className="w-8 h-8"
                                  alt={item.name}
                                />
                              )}
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900 dark:text-white">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-xs text-gray-600">
                                  {item.description}
                                </p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className="justify-around px-5 py-5 space-y-6 bg-slate-100 dark:bg-slate-800 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {contact.map((item) => (
                            <div key={item.name} className="flow-root">
                              <Popover.Button as={NavLink} to="/contact">
                                <div className="flex items-center p-3 -m-3 text-base font-medium transition-all rounded-md group">
                                  <item.icon
                                    className="flex-shrink-0 w-6 h-6 dark:text-white group-hover:text-gray-600"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3 dark:text-white group-hover:text-gray-600">
                                    {item.name}
                                  </span>
                                </div>
                              </Popover.Button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>

          {/* Dark Mode Toggle */}
          <div className="items-center justify-end hidden md:flex md:flex-1 lg:w-0">
            <DarkModeSwitch />
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-10 p-2 transition origin-top-right transform md:hidden"
        >
          <div className="transition-all bg-white rounded-lg shadow-lg dark:bg-gray-900">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                {/* Harris Avatar */}
                <div>
                  <img
                    className="w-auto h-8"
                    src={HarrisIcon}
                    alt="Harris Khawar Avatar"
                  />
                </div>

                <div className="flex items-center -mr-2">
                  {/* Dark Mode Toggle  */}
                  <DarkModeSwitch />

                  {/* Close Menu */}
                  <Popover.Button className="inline-flex items-center justify-center p-2 ml-3 text-gray-400 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="w-6 h-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="px-5 pt-6">
                <div className="grid grid-cols-2 gap-x-8 justify-items-center">
                  {/* Travel Page */}
                  <NavLink to="/travel">
                    <div className="flex items-center">
                      <GlobeIcon className="w-4 h-4 mr-2 dark:text-white" />
                      <p className="font-semibold dark:text-white">Travel</p>
                    </div>
                  </NavLink>

                  {/* Resume Download */}
                  <a href={Resume} download>
                    <div className="flex items-center">
                      <DocumentDownloadIcon className="w-4 h-4 mr-2 dark:text-white" />
                      <p className="font-semibold dark:text-white">Resume</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            {/* Projects */}
            <div className="px-5 py-6 border-t">
              <nav className="grid gap-y-8">
                {projects.map((item) => (
                  <Popover.Button
                    as={NavLink}
                    key={item.name}
                    to={`/projects/${item.link}`}
                    className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50 dark:hover:bg-gray-700"
                  >
                    <item.icon
                      className="flex-shrink-0 w-6 h-6 cursor-pointer text-primary"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base font-medium text-gray-900 cursor-pointer dark:text-white">
                      {item.name}
                    </span>
                  </Popover.Button>
                ))}
              </nav>
            </div>

            <div className="px-5 py-6 space-y-6 border-t">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                {socialMedia.map((item) => (
                  <a
                    key={item.name}
                    href={item.link}
                    className="flex items-center text-base font-medium text-gray-900 dark:text-white hover:text-gray-700"
                  >
                    <img
                      src={item.image}
                      className="w-4 h-4 mr-2"
                      alt={item.name}
                    />
                    {item.name}
                  </a>
                ))}
                {contact.map((item) => (
                  <div key={item.name} className="flow-root">
                    <Popover.Button as={NavLink} to="/contact">
                      <div className="flex items-center text-base font-medium text-gray-900 dark:text-white hover:text-gray-700">
                        <item.icon
                          className="w-4 h-4 mr-2"
                          aria-hidden="true"
                        />

                        {item.name}
                      </div>
                    </Popover.Button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

import AngularLogo from "../img/angular-logo.png";
import HerokuLogo from "../img/heroku-logo.png";
import BootstrapLogo from "../img/bootstrap-logo.png";
import EJSLogo from "../img/ejs-logo.png";
import JavascriptLogo from "../img/javascript-logo.png";
import GithubLogo from "../img/github-logo.png";
import ReactLogo from "../img/react-logo.png";
import TailwindLogo from "../img/tailwind-logo.png";
import FirebaseLogo from "../img/firebase-logo.png";
import NextLogo from "../img/next-logo.png";
import ShopifyLogo from "../img/shopify-logo.png";
import PhotoshopLogo from "../img/photoshop-logo.png";
import MongoDBLogo from "../img/mongodb-logo.png";
import JQueryLogo from "../img/jquery-logo.png";

import PoshImage from "../img/projects/posh.jpg";
import CasesmartImage from "../img/projects/casesmart.jpg";
import IronwoodImage from "../img/projects/ironwood.jpg";
import SkootsImage from "../img/projects/skoots.png";
import ConsequencesImage from "../img/projects/consequences.png";
import MerlinImage from "../img/projects/merlin.png";
import ReactQuotesImage from "../img/projects/react-quotes.png";
import ReactMealsImage from "../img/projects/react-meals.jpg";
import ReactMeetupsImage from "../img/projects/react-meetups.png";
import toDoListImage from "../img/projects/to-do-list.png";
import SimonImage from "../img/projects/simon.png";
import RockPaperScissorsImage from "../img/projects/rock-paper-scissors.jpg";
import DyceImage from "../img/projects/dyce.png";
import DrumzImage from "../img/projects/drumz.png";
import WloatImage from "../img/projects/wloat.png";
import TenduaImage from "../img/projects/tendua.png";
import ExpenseTrackerImage from "../img/projects/expense-tracker.png";

const projects = [
  {
    title: "POSH",
    description: "A car subscription service startup I am working on.",
    image: PoshImage,
    link: "https://posh-dev.vercel.app/",
    logos: [NextLogo, ReactLogo, TailwindLogo, FirebaseLogo],
  },
  {
    title: "Ironwood Marketing",
    description: "The official website for the Ironwood Marketing.",
    image: IronwoodImage,
    link: "https://ironwoodmarketing.com/",
    logos: [BootstrapLogo, JavascriptLogo, GithubLogo],
  },
  {
    title: "Casesmart",
    description: "An ecommerce store for cell phone accessories.",
    image: CasesmartImage,
    link: "https://casesmart.com/",
    logos: [ShopifyLogo, PhotoshopLogo],
  },
  {
    title: "Skoots",
    description: "A Blockchain-based bike sharing platform.",
    image: SkootsImage,
    link: "https://cp-skoots.herokuapp.com/",
    logos: [AngularLogo, BootstrapLogo, HerokuLogo],
  },
  {
    title: "Consequences",
    description: "A textual implementation of the game exquisite-corpse.",
    image: ConsequencesImage,
    link: "https://pp-consequences-app.herokuapp.com/",
    logos: [EJSLogo, BootstrapLogo, MongoDBLogo, HerokuLogo],
  },
  {
    title: "React Quotes",
    description: "A webapp for collecting quotes built as a practice project.",
    image: ReactQuotesImage,
    link: "https://react-mspa.web.app/",
    logos: [ReactLogo, BootstrapLogo, FirebaseLogo],
  },
  {
    title: "React Meals",
    description: "A webapp for ordering food built as a practice project.",
    image: ReactMealsImage,
    link: "https://harriskhawar.com/not-found",
    logos: [ReactLogo, BootstrapLogo, FirebaseLogo],
    notDeployed: true,
  },
  {
    title: "React Meetups",
    description:
      "A webapp for finding and creating meetup spots built as a practice project.",
    image: ReactMeetupsImage,
    link: "https://harriskhawar.com/not-found",
    logos: [ReactLogo, BootstrapLogo, FirebaseLogo],
    notDeployed: true,
  },
  {
    title: "Merlin",
    description: "A virtual implementation of the 90s game Merlin.",
    image: MerlinImage,
    link: "https://merlin-chatbot.herokuapp.com/",
    logos: [JavascriptLogo, GithubLogo],
  },
  {
    title: "To Do List",
    description: "A simple to-do list app built as a practice project.",
    image: toDoListImage,
    link: "https://tendua-todolist.herokuapp.com/",
    logos: [JavascriptLogo, EJSLogo, BootstrapLogo, HerokuLogo],
  },
  {
    title: "Simon",
    description: "A simple Simon game built as a practice project.",
    image: SimonImage,
    link: "https://harriskhawar.github.io/pp-simon/",
    logos: [JQueryLogo, BootstrapLogo, GithubLogo],
  },
  {
    title: "Rock Paper Scissors",
    description:
      "A virtual single player rock paper scissors game built as a practice project.",
    image: RockPaperScissorsImage,
    link: "https://harriskhawar.github.io/pp-rock-paper-scissors/",
    logos: [JavascriptLogo, GithubLogo],
  },
  {
    title: "Dyce",
    description: "A two-player dice game built as a practice project.",
    image: DyceImage,
    link: "https://harriskhawar.github.io/pp-dyce/",
    logos: [JQueryLogo, GithubLogo],
  },
  {
    title: "Drumz",
    description: "A virtual drumpad built as a practice project.",
    image: DrumzImage,
    link: "https://harriskhawar.github.io/pp-drumz/",
    logos: [JavascriptLogo, GithubLogo],
  },
  {
    title: "Wloat",
    description: "A landing page for an imaginary community of crazy people.",
    image: WloatImage,
    link: "https://harriskhawar.com/not-found/",
    logos: [JQueryLogo, BootstrapLogo, GithubLogo],
    notDeployed: true,
  },
  {
    title: "Tendua",
    description:
      "A sign-up for newsletter webpage built to practice handling user input and databases.",
    image: TenduaImage,
    link: "http://tendua.herokuapp.com/",
    logos: [JQueryLogo, BootstrapLogo, MongoDBLogo, HerokuLogo],
  },
  {
    title: "Expense Tracker",
    description: "A webapp for tracking and monitoring expenses built as a practice project.",
    image: ExpenseTrackerImage,
    link: "https://harriskhawar.com/not-found/",
    logos: [ReactLogo, BootstrapLogo],
    notDeployed: true,
  },
];

export default projects;
